export default {
    data() {
        return {
            scrollToSettings: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            }
        }
    },
    methods: {
        scrollIntoView(node) {
            node.scrollIntoView(this.scrollToSettings);
        }
    }
}
